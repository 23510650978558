<template>
  <div class="container">
    <a-button
      @click="$router.back()"
      class="mb-2"
      style="background-image: linear-gradient(to right, #1D2671 , #830051); color: white;border-radius: 20px;"
    >
      <LeftOutlined />Kembali
    </a-button>
    <div
      v-for="category in faqData"
      :key="category.name"
      class="category card py-4 px-5 my-4 shadow-sm"
    >
      <div class="d-flex justify-content-between align-items-center">
        <p
          class="category-header"
          @click="toggleCategory(category.name)"
          style="font-size: 24px; font-weight: bold;"
        >
          {{ category.name }}
        </p>
        <span @click="toggleCategory(category.name)" class="toggle-icon">
          <CloseOutlined v-if="category.isOpen" />
          <PlusOutlined v-else />
        </span>
      </div>
      <transition name="slide">
        <ul v-show="category.isOpen" class="subcategory-list my-2">
          <li
            v-for="subCategory in category.subcategory"
            :key="subCategory.name"
            class="subcategory card my-4 py-4 px-5"
          >
            <div class="d-flex justify-content-between align-items-center">
              <p
                class="subcategory-header"
                @click="toggleSubCategory(subCategory.name)"
                style="font-size: 18px;"
              >
                {{ subCategory.name }}
              </p>
              <span @click="toggleSubCategory(subCategory.name)" class="toggle-icon">
                <CloseOutlined v-if="subCategory.isOpen" />
                <PlusOutlined v-else />
              </span>
            </div>
            <transition name="slide">
              <div v-show="subCategory.isOpen">
                <div v-for="help in subCategory.help" :key="help.title" class="help-item my-4">
                  <div class="d-flex align-items-center">
                    <div class="help-content" @click="toggleHelp(help.title)">
                      <strong>{{ help.title }}</strong>
                      <br />
                      <div style="white-space: pre-line; margin-top: 10px;">
                        {{ help.body }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </transition>
          </li>
        </ul>
      </transition>
    </div>
  </div>
</template>
<script>
import { getHelp } from '../../services/axios/pulih'
import { PlusOutlined, CloseOutlined, LeftOutlined } from '@ant-design/icons-vue'

import { onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
export default {
  components: {
    PlusOutlined,
    CloseOutlined,
    LeftOutlined,
  },
  setup() {
    const faqData = ref([
      {
        name: 'Pertanyaan Umum',
        isOpen: false,
        subcategory: [
          {
            name: 'LVNG Website',
            isOpen: false,
            help: [
              {
                title: 'Alamat website LVNG',
                body: 'www.lvngwithindonesia.com',
                isOpen: false,
              },
            ],
          },
          {
            name: 'Pengaturan Akun',
            isOpen: false,
            help: [
              {
                title: 'Bagaimana cara logout',
                body: 'Anda dapat melakukan logout pada menu profil',
                isOpen: false,
              },
              {
                title: 'Bagaimana jika saya ingin menghapus akun saya',
                body: 'Anda dapat menghubungi kami di pusat bantuan untuk menghapus akun',
                isOpen: false,
              },
            ],
          },
        ],
      },
      {
        name: 'Pertanyaan yang Sering Diajukan',
        isOpen: false,
        subcategory: [
          {
            name: 'Tentang Aplikasi',
            isOpen: false,
            help: [
              {
                title: 'Bagaimana alur proses untuk program ini?',
                body:
                  '5 langkah mudah mengakses aplikasi ‘Pulih’: Pasien telah mendapatkan resep terapi...',
                isOpen: false,
              },
            ],
          },
          {
            name: 'Tentang Efek Samping',
            isOpen: false,
            help: [
              {
                title: 'Bagaimana jika saya mengalami efek samping setelah penggunaan produk?',
                body:
                  'Jika mengalami efek samping, harap segera berkonsultasi dengan tenaga kesehatan...',
                isOpen: false,
              },
            ],
          },
        ],
      },
    ])
    const router = useRouter()

    const fetchData = async () => {
      const res = await getHelp()
      faqData.value = res
    }

    onMounted(() => {
      fetchData()
    })
    const handleBack = () => {
      router.push('/dashboard')
    }

    return {
      faqData,
      handleBack,
    }
  },
  methods: {
    toggleCategory(categoryName) {
      this.faqData.forEach(category => {
        if (category.name === categoryName) {
          category.isOpen = !category.isOpen
        }
      })
    },
    toggleSubCategory(subCategoryName) {
      this.faqData.forEach(category => {
        category.subcategory.forEach(sub => {
          if (sub.name === subCategoryName) {
            sub.isOpen = !sub.isOpen
          }
        })
      })
    },
    toggleHelp(helpTitle) {
      this.faqData.forEach(category => {
        category.subcategory.forEach(sub => {
          sub.help.forEach(help => {
            if (help.title === helpTitle) {
              help.isOpen = !help.isOpen
            }
          })
        })
      })
    },
  },
}
</script>
<style>
.faq-container {
  margin: 100px;
}
.card {
  border-radius: 20px;
  padding: 15px;
  transition: all 0.3s ease-in-out;
}
.card:hover {
  transform: translateY(-5px);
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}
.category-header,
.subcategory-header,
.help-content {
  cursor: pointer;
  /* font-weight: bold; */
}
ul {
  list-style-type: none;
  padding-left: 15px;
}
.help-body {
  white-space: pre-line; /* This will respect newlines (\n) in the text */
}
.toggle-icon {
  cursor: pointer;
}
</style>
